import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Pharms 開発チームが取り組んでいるチームビルディング \"TGIF\" とは？",
  "date": "2023-01-31T02:20:34.000Z",
  "slug": "entry/2023/01/31/112033",
  "tags": ["medley"],
  "hero": "./2023_01_31.png",
  "heroAlt": "Pharms TGIF"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`はじめに`}</h1>
    <p>{`みなさん、こんにちは。エンジニアの山田です。今回は `}<a parentName="p" {...{
        "href": "https://pharms-cloud.com/"
      }}>{`Pharms`}</a>{` 開発チームがチームビルディングの一環として行なっている「TGIF」という施策について参加している皆さんに、どのような効果があるのかなどを聞いていこうと思います。`}</p>
    <h1>{`インタビュイー紹介`}</h1>
    <h2>{`新居さん`}</h2>
    <p>{`2016 年入社。ソーシャルゲーム開発会社を経て、メドレーへ入社。入社当初はジョブメドレーのプロダクト開発などを担当していたが、2020 年から Pharms で中規模施策の開発を中心に業務を行なう。`}</p>
    <h2>{`小田さん`}</h2>
    <p>{`2021 年入社。大手メーカーで病院や調剤薬局向けの IT システムの開発業務を経て、メドレー入社。Pharms では中規模施策の開発を中心に業務を行なう。`}</p>
    <h2>{`兒玉さん`}</h2>
    <p>{`2018 年入社。2021 年 4 月まで CLINICS オンライン診療のセールス部に所属。その後エンジニアに社内転職し、Pharms の開発に携わる。現在はサーバサイドの開発を中心に担当している。`}</p>
    <h2>{`新倉さん`}</h2>
    <p>{`2021 年入社。不動産テック企業でのデザイン業務を経て、メドレーへ入社。入社時から Pharms のデザイナーとして各種デザインの業務を行なう。`}</p>
    <h2>{`古川さん`}</h2>
    <p>{`2022 年に新卒入社。現在はプロダクトの改善を幅広く担当している。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8ecff1febafd46754dcdc6587d677881/eea4a/note202301_001.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAgX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAP/aAAwDAQACEAMQAAABvG6CgPkf/8QAGhAAAgIDAAAAAAAAAAAAAAAAAQIDEhETIP/aAAgBAQABBQJ3FllNNWeP/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8Bp//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/AYf/xAAbEAABBAMAAAAAAAAAAAAAAAAAAQIRISAxMv/aAAgBAQAGPwLi7GSzak4f/8QAGhAAAwADAQAAAAAAAAAAAAAAAREhACBRgf/aAAgBAQABPyFYbLiZeDR5jsBF7p//2gAMAwEAAgADAAAAEDD/AP/EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/ECv/xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8QMj//xAAcEAEBAAEFAQAAAAAAAAAAAAABEQAQITFRYeH/2gAIAQEAAT8QGQh3Kpe++rknebLAbV2p8wdDuoOPDzIHGv8A/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Pharms 開発チームのみなさん",
            "title": "Pharms 開発チームのみなさん",
            "src": "/static/8ecff1febafd46754dcdc6587d677881/e5166/note202301_001.jpg",
            "srcSet": ["/static/8ecff1febafd46754dcdc6587d677881/f93b5/note202301_001.jpg 300w", "/static/8ecff1febafd46754dcdc6587d677881/b4294/note202301_001.jpg 600w", "/static/8ecff1febafd46754dcdc6587d677881/e5166/note202301_001.jpg 1200w", "/static/8ecff1febafd46754dcdc6587d677881/eea4a/note202301_001.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h1>{`Pharms というプロダクトについて`}</h1>
    <p><strong parentName="p">{`山田`}</strong>{`: まず「TGIF」について聞いていく前に、Pharms とはどのようなプロダクトなのか教えてもらってもよいですか?`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: 一言で言うと、薬局の業務効率化支援システムです。`}<a parentName="p" {...{
        "href": "https://clinics-cloud.com/online"
      }}>{`CLINICS オンライン診療`}</a>{`で診察を受けた患者さんや、直接来院された患者さんからの処方箋などを受けて、Pharms を通して服薬指導や、会計、服薬フォローアップなどを一貫して行なえるシステムになっています。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5629e6200f754ea817912f4d3970ae67/eea4a/note202301_011.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBv/EABUBAQEAAAAAAAAAAAAAAAAAAAAC/9oADAMBAAIQAxAAAAG5aYjQCCX/xAAaEAABBQEAAAAAAAAAAAAAAAABAAIDBDMR/9oACAEBAAEFArDmiKKdhXVdzORX/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8BR//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/AVf/xAAcEAABAwUAAAAAAAAAAAAAAAAAAhBxAxIiMUH/2gAIAQEABj8Cy6W6ZMlOW//EABsQAQACAgMAAAAAAAAAAAAAAAEAESFBEDFh/9oACAEBAAE/IWBakGI6asF75aAUAXL2dp//2gAMAwEAAgADAAAAENA//8QAFhEAAwAAAAAAAAAAAAAAAAAAEBFB/9oACAEDAQE/EIg//8QAFxEBAAMAAAAAAAAAAAAAAAAAAAERUf/aAAgBAgEBPxCNWf/EABwQAQEAAgIDAAAAAAAAAAAAAAERAGEhMVGR8P/aAAgBAQABPxBGYodlt9cYcb3IhprEjLnwacfMKgjCS+cXPP/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "新居さん",
            "title": "新居さん",
            "src": "/static/5629e6200f754ea817912f4d3970ae67/e5166/note202301_011.jpg",
            "srcSet": ["/static/5629e6200f754ea817912f4d3970ae67/f93b5/note202301_011.jpg 300w", "/static/5629e6200f754ea817912f4d3970ae67/b4294/note202301_011.jpg 600w", "/static/5629e6200f754ea817912f4d3970ae67/e5166/note202301_011.jpg 1200w", "/static/5629e6200f754ea817912f4d3970ae67/eea4a/note202301_011.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`新居さん`}</em></p>
    <p><strong parentName="p">{`山田`}</strong>{`: ユーザーについてですが、例えば、自分が開発を担当している医療介護求人サイトの`}<a parentName="p" {...{
        "href": "https://job-medley.com/"
      }}>{`ジョブメドレー`}</a>{`では、お仕事をお探しの求職者の方々と、求人情報を掲載いただく医院・事業所の方々が使用しているシステムなのですが、Pharms はどのような方に使われるのでしょうか?`}</p>
    <p><strong parentName="p">{`小田`}</strong>{`: CLINICS アプリを通して間接的に患者さんが使っていますが、大きい割合を占めるのは顧客である薬局のスタッフさん（薬剤師など）ですね。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c0e66c5f10e14318214d7b2f47d6e347/eea4a/note202301_007.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQDBQb/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABuUpgc+KjP//EABsQAAIBBQAAAAAAAAAAAAAAAAADAgETIzIz/9oACAEBAAEFAh2Fd2ZLR/Cp/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGxAAAgEFAAAAAAAAAAAAAAAAAAIxARAzcZH/2gAIAQEABj8CKsswZG6MNq3/xAAYEAEBAQEBAAAAAAAAAAAAAAABEQAxEP/aAAgBAQABPyEbqLFJxzKtfBhaZ3v/2gAMAwEAAgADAAAAEGP/AP/EABYRAQEBAAAAAAAAAAAAAAAAABEBEP/aAAgBAwEBPxAJn//EABYRAAMAAAAAAAAAAAAAAAAAAAEQMf/aAAgBAgEBPxClf//EAB0QAQACAgIDAAAAAAAAAAAAAAEAESExQbFxofD/2gAIAQEAAT8QJt2BtYqHcOgzWPtxZaLwiemdwggTg+SdM//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "小田さん",
            "title": "小田さん",
            "src": "/static/c0e66c5f10e14318214d7b2f47d6e347/e5166/note202301_007.jpg",
            "srcSet": ["/static/c0e66c5f10e14318214d7b2f47d6e347/f93b5/note202301_007.jpg 300w", "/static/c0e66c5f10e14318214d7b2f47d6e347/b4294/note202301_007.jpg 600w", "/static/c0e66c5f10e14318214d7b2f47d6e347/e5166/note202301_007.jpg 1200w", "/static/c0e66c5f10e14318214d7b2f47d6e347/eea4a/note202301_007.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`小田さん`}</em></p>
    <p><strong parentName="p">{`山田`}</strong>{`: やはり現場の薬剤師さんが使っているサービスという性格が強いんですね。`}</p>
    <h1>{`Pharms 開発チームの TGIF という試みについて`}</h1>
    <h2>{`TGIF を始める前の課題感`}</h2>
    <p><strong parentName="p">{`山田`}</strong>{`: それでは、いよいよ Pharms 開発チームで行なっている TGIF について聞いていきたいと思います。元々、この言葉はどんな経緯で付いたんですか?`}</p>
    <p><strong parentName="p">{`小田`}</strong>{`: 元々は英語圏で言われている "Thank God It's Friday" というのが語源で、そのまま金曜日の夕方に開催されるものだったのでこの名前を付けています。ちょっと昔の言葉で言うと「花金」ですね。`}</p>
    <p><strong parentName="p">{`山田`}</strong>{`: 「1 週間が終わりだー!」っていう開放感を表わしてるんですね。どういうきっかけでこの TGIF をやっていこうと思ったんでしょうか?`}</p>
    <p><strong parentName="p">{`小田`}</strong>{`: 一昨年の自分の入社前のタイミングで、Pharms 立ち上げ時のメンバーが別のプロジェクトに集中することとなり、チーム体制がガラッと変わる時期がありました。それ以降、チーム内の情報がサイロ化したり、チームとしての一体感がないという課題を感じていました。この課題を解決するために`}<strong parentName="p">{`雑多に何でも話せるコミュニケーションの場`}</strong>{`を作ろうというのが、きっかけでした。`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: もちろん、それまでもチームで話す機会が全く無かったわけではなく、開発計画をクォーター単位で作っているのでそのタイミングでディスカッションする場を設けていました。でも `}<strong parentName="p">{`もうちょっとコミュニケーションの頻度を増やしたらより良くなりそうだよね`}</strong>{` という話があり、毎週金曜日の TGIF が誕生しました。`}</p>
    <p><strong parentName="p">{`山田`}</strong>{`: そういう経緯だったんですね。それまでエンジニア・デザイナーが参加する定例のような会議体はどのようなものがあったんですか?`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: クォーター毎のディスカッション以外だと、週一で行なわれる「プロダクト定例」だけでした。こちらは開発メンバーだけではなく、事業部のメンバーも参加してプロダクト開発全般についての共有を目的にしたものです。それ以外で定例の会議体はなく、デイリーで行われるような「朝会・夕会」も Pharms 開発チームでは行なっていませんでした。`}</p>
    <p><strong parentName="p">{`山田`}</strong>{`: チーム体制の変更後、新しいメンバーの加入もあり、今までのコミュニケーション量では足りなくなってしまったんですね。`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: はい。チームの状況が変わって行く中で、`}<strong parentName="p">{`もっと生産性を上げていきたいという思いがありました`}</strong>{` 。チームが小規模だった頃は隣の席の同僚エンジニアに相談しつつ開発をドンドン前に進めていくことができたんですが、人数が増えてくるとそうしたコミュニケーションも希薄になります。具体的には、開発をしていて設計方針・実装方針などがズレることは多々あると思うんですが、それをさっと会話して軌道修正し、開発を推進していくというのが難しくなってきていました。`}</p>
    <h2>{`TGIF の実際`}</h2>
    <p><strong parentName="p">{`山田`}</strong>{`: では、具体的に TGIF でどのような事を話し合われるんでしょうか?`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: まずは今週の振り返りから始まります。前週の TGIF で Try に対して個々がどう動いていくかを決めているので、それに対して振り返りをしていきます。各自コメントを添えて何%達成したのか話していきます。`}</p>
    <p><strong parentName="p">{`小田`}</strong>{`: その後、今週のチーム全体の動きに対して KPT を実施します。チーム全体として良かった動きや課題などを挙げていき、内容を深ぼったり課題解決に向けて意見を交換したりしています。また、以前はチームの動きとは関係ない個人の話などが挙がってくることが多かったのですが、 `}<strong parentName="p">{`チーム全体での生産性の向上ということを目的として考えた場合、それと関連のない個人の話をしても寄与しない`}</strong>{` ので、チーム全体に関わることをメインにするよう改善し今の形になってきました。`}</p>
    <p>{`KPT が終わったら、隔週でチーム目標と Problem で挙がったことなども踏まえて各自の来週の Try を決めます。`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: 最後に、ちょっとしたことでも良いのでチームに共有したい「気になること」を話していきます。ここは開発に関係する・しないに関わらない話題を出しています。例えば 、Pharms のプロダクト自体や会社の組織的なこと、プルリクエストのレビューのお願いなど雑多な話題ですね。`}</p>
    <p>{`その中で「ライブラリアップデート」についての課題や進捗などの共有もします。単純にバージョンアップして済むというものであれば良いですが、アップデートの結果、広範囲に影響があったりだとか、そもそもエラーなどで上手くアップデートできないなどがあるので、そうした困り事の共有と解決を目的としています。`}</p>
    <p><strong parentName="p">{`山田`}</strong>{`: 先ほどチーム目標を隔週で決めるというお話でしたが、こちらはどういう風に決めていくんですか?`}</p>
    <p><strong parentName="p">{`小田`}</strong>{`: KPT の内容を踏まえて、基本的には `}<a parentName="p" {...{
        "href": "https://www.medley.jp/team/culture.html"
      }}>{`Our Essentials`}</a>{`(以下 OE) に照らし合わせて決めていきます。そのチーム目標に対して、個人の目標をブレイクダウンして決める形にしています。`}</p>
    <h2>{`固くなりすぎない会を目指して`}</h2>
    <p><strong parentName="p">{`山田`}</strong>{`: なるほど。 `}<strong parentName="p">{`OE に合わせて目標設定していくというのは、分かりやすくて良い`}</strong>{` ですね。話は少し逸れますが、この TGIF では毎回お菓子を持ち寄って行なっているということですが、どうしてなのでしょうか?`}</p>
    <p><strong parentName="p">{`小田`}</strong>{`: TGIF を行なっている内に、メンバー間での意思疎通の少なさによって、チームの生産性が上がらないという課題は解決していきました。しかし、続けている内に段々と会自体が固い雰囲気になってきて、かっちりとした振り返りの場という感じになってしまいました。元々 TGIF の在り方としてはどういうものだっけという原点に立ち返ると、やはり `}<strong parentName="p">{`「気軽にコミュニケーションができる場」という点が重要`}</strong>{` だと再認識しまして、そこからは場の雰囲気を柔らげるためにお菓子を持ち寄り始めました。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/30fd3fe4cca4fcf71b52f14e80de234b/eea4a/IMG_0914.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIEAQP/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABuxuUMRh//8QAHRAAAQQCAwAAAAAAAAAAAAAAAQACAxEEEhMyM//aAAgBAQABBQJjW6mguaNQ9MnyAFf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAZEAEAAgMAAAAAAAAAAAAAAAABACAhIjH/2gAIAQEABj8C1iuApyf/xAAdEAACAQQDAAAAAAAAAAAAAAAAATERIWFxgbHx/9oACAEBAAE/Id6llh8VSNfhDtncMJVHwf/aAAwDAQACAAMAAAAQTC//xAAWEQEBAQAAAAAAAAAAAAAAAAAAETH/2gAIAQMBAT8QxX//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPxBH/8QAHxABAAICAAcAAAAAAAAAAAAAAQARIUExYXGBsdHh/9oACAEBAAE/ELMSWocLWuRGWbReS3bxGjF2b94lZbXI9WNBSnyx3arih3P/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "お菓子を囲んでの TGIF",
            "title": "お菓子を囲んでの TGIF",
            "src": "/static/30fd3fe4cca4fcf71b52f14e80de234b/e5166/IMG_0914.jpg",
            "srcSet": ["/static/30fd3fe4cca4fcf71b52f14e80de234b/f93b5/IMG_0914.jpg 300w", "/static/30fd3fe4cca4fcf71b52f14e80de234b/b4294/IMG_0914.jpg 600w", "/static/30fd3fe4cca4fcf71b52f14e80de234b/e5166/IMG_0914.jpg 1200w", "/static/30fd3fe4cca4fcf71b52f14e80de234b/eea4a/IMG_0914.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`お菓子を囲んでの TGIF`}</em></p>
    <p><strong parentName="p">{`山田`}</strong>{`: 確かに固すぎると、ちょっと会の名前とそぐわない感じになってしまいますね。`}</p>
    <h2>{`TGIF 運用の秘訣`}</h2>
    <p><strong parentName="p">{`山田`}</strong>{`: 他に TGIF を運用する上での工夫などはありますか?`}</p>
    <p><strong parentName="p">{`新倉`}</strong>{`: 会の性質上、話が盛り上がって時間がオーバーしがちなので「 TGIF で話す内容はチームの成長につながることに限定する」など、 `}<strong parentName="p">{`効率的かつ意味のある運用`}</strong>{` になるよう進め方の見直しと実践は日々試行錯誤しています。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e8d5f0453b6e424b0160d6df8206110b/eea4a/note202301_008.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAQF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQAC/9oADAMBAAIQAxAAAAHXzaoctAL/xAAZEAEAAwEBAAAAAAAAAAAAAAADAAECEjT/2gAIAQEAAQUCROMGtWyB2iTfon//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPwFH/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8BV//EABsQAAIBBQAAAAAAAAAAAAAAAAABAhARITFR/9oACAEBAAY/ArnMIclPYxU//8QAGxABAAIDAQEAAAAAAAAAAAAAAQARIUGhEIH/2gAIAQEAAT8huKW6LhErIvowinLZOROPz//aAAwDAQACAAMAAAAQ5P8A/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEx/9oACAEDAQE/EMQ//8QAFhEBAQEAAAAAAAAAAAAAAAAAAAEx/9oACAECAQE/ENW3/8QAHBABAAMAAgMAAAAAAAAAAAAAAQARITFBYZHB/9oACAEBAAE/ECJ6GDajMR5rEHxYMeWJbTE9nBPE4vVM9wDWuWf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "新倉さん",
            "title": "新倉さん",
            "src": "/static/e8d5f0453b6e424b0160d6df8206110b/e5166/note202301_008.jpg",
            "srcSet": ["/static/e8d5f0453b6e424b0160d6df8206110b/f93b5/note202301_008.jpg 300w", "/static/e8d5f0453b6e424b0160d6df8206110b/b4294/note202301_008.jpg 600w", "/static/e8d5f0453b6e424b0160d6df8206110b/e5166/note202301_008.jpg 1200w", "/static/e8d5f0453b6e424b0160d6df8206110b/eea4a/note202301_008.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`新倉さん`}</em></p>
    <p><strong parentName="p">{`古川`}</strong>{`: 自分は去年の新卒入社ですので、「言ってもしょうがないかな?」というような事もあるのですが、あえてそういった事を考えずに積極的に発言するようにしています。 `}<strong parentName="p">{`実際に発言したために他の方との共有もできますし、学びになるような議論に発展することも多い`}</strong>{` ので、言って良かったという場合が多いです。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/23b2c805befe1b3de31a5a55b59ed0b3/eea4a/note202301_003.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAgP/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABbhVcbGJN/wD/xAAaEAACAwEBAAAAAAAAAAAAAAACAwEEMgBB/9oACAEBAAEFAnshYJaA15kW9dxXz5//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAcEAACAgIDAAAAAAAAAAAAAAAAAhEhEHESMUL/2gAIAQEABj8C6maGjycnWxdj6x//xAAcEAACAgIDAAAAAAAAAAAAAAAAAREhMaFRcbH/2gAIAQEAAT8heZkxDmpGWimkuGbDw1AqTo//2gAMAwEAAgADAAAAEBgv/8QAFxEBAAMAAAAAAAAAAAAAAAAAAQARMf/aAAgBAwEBPxDWoBWT/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERMf/aAAgBAgEBPxBZR26f/8QAGxABAQEBAAMBAAAAAAAAAAAAAREhABAxQWH/2gAIAQEAAT8QEtiUw01XiqfZdz5OUJVNJDDwQBBMQf0rwAhCMO//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "古川さん",
            "title": "古川さん",
            "src": "/static/23b2c805befe1b3de31a5a55b59ed0b3/e5166/note202301_003.jpg",
            "srcSet": ["/static/23b2c805befe1b3de31a5a55b59ed0b3/f93b5/note202301_003.jpg 300w", "/static/23b2c805befe1b3de31a5a55b59ed0b3/b4294/note202301_003.jpg 600w", "/static/23b2c805befe1b3de31a5a55b59ed0b3/e5166/note202301_003.jpg 1200w", "/static/23b2c805befe1b3de31a5a55b59ed0b3/eea4a/note202301_003.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`古川さん`}</em></p>
    <p><strong parentName="p">{`兒玉`}</strong>{`: ささいな事でも、誰かが `}<strong parentName="p">{`チームの為に動いた事に対しては積極的にお礼を言っています`}</strong>{` 。誰かがちゃんと自分のやったことを見てくれていると認識できることが、チームの雰囲気を良くし、チーム運営の好循環に繋がっているかと思います。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/dcb7fa8202a88ba590e2bcf67016e12f/eea4a/note202301_009.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAgP/xAAUAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHbEZ0ah3E//8QAGxAAAQQDAAAAAAAAAAAAAAAAAgABAwQRMTP/2gAIAQEAAQUCrHIz1DAlglHzo6X/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwFX/8QAHhAAAgEDBQAAAAAAAAAAAAAAAAIRAQOCEBIhInL/2gAIAQEABj8Cd7jdYkrtpBw0YlzyZaf/xAAdEAACAQQDAAAAAAAAAAAAAAAAAREhMUGhYXHB/9oACAEBAAE/IXOmklcjLnIZBpVDuLE/DL00Kx//2gAMAwEAAgADAAAAEIPP/8QAFhEBAQEAAAAAAAAAAAAAAAAAABFR/9oACAEDAQE/ELiP/8QAFhEAAwAAAAAAAAAAAAAAAAAAEBFR/9oACAECAQE/EFQ//8QAHBABAAIDAQEBAAAAAAAAAAAAAREhADFRQWFx/9oACAEBAAE/EHHr2eivOVkgzUaysrLP7nsC2utiNzgCKKUGm8U6bPk6wDoZ/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "兒玉さん",
            "title": "兒玉さん",
            "src": "/static/dcb7fa8202a88ba590e2bcf67016e12f/e5166/note202301_009.jpg",
            "srcSet": ["/static/dcb7fa8202a88ba590e2bcf67016e12f/f93b5/note202301_009.jpg 300w", "/static/dcb7fa8202a88ba590e2bcf67016e12f/b4294/note202301_009.jpg 600w", "/static/dcb7fa8202a88ba590e2bcf67016e12f/e5166/note202301_009.jpg 1200w", "/static/dcb7fa8202a88ba590e2bcf67016e12f/eea4a/note202301_009.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`兒玉さん`}</em></p>
    <p><strong parentName="p">{`新居`}</strong>{`: ちゃんとチーム全員にとって意味がある時間にするということは意識しています。仮にあまり生産的な時間ではなくなったら TGIF を止めてしまっても良いと思っています。その為には `}<strong parentName="p">{`「チームのために話しをした方がよいことは必ず話す」「本音ベースで建設的に議論する」「出てきた課題は必ずクリアにして次週以降に解決を目指す」`}</strong>{` という事を意識しています。`}</p>
    <p>{`こうして話すとある意味当たり前の事ばかりなんですが、参加者全員がこうした点を共通認識として持っていないと、単に KPT を発表するだけの場となり会自体が形骸化していってしまいます。ですので、きちんとこの共通認識を持って臨めるようにするというのを大切にしています。`}</p>
    <p><strong parentName="p">{`小田`}</strong>{`: また、TGIF で話が盛り上がるのは良いことなのですが、週に一回のこの場を待たずにもっと会話を活性化する取り組みとして `}<strong parentName="p">{`週の頭に Slack のプロダクトチャンネルにテディベアがリマインダーで現れる`}</strong>{` ので、そのスレッドで話しをするようにしています。`}</p>
    <p><strong parentName="p">{`山田`}</strong>{`: へえー!何でテディベアなんですか?`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: プログラミングで自分で解決できない問題が出てきたときに、テディベアのぬいぐるみに話しかけると問題が整理されて解決できるというテディベア効果から取っています。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "618px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/41b06ba3cba40cf0f2a1f14838b386fd/6e6fb/image2023-1-6_19-45-27.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.333333333333332%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAnklEQVQI133MvQ7BUABAYc9gaOteblX/tK6fNjQxNBUkQouIpTa7zeD1j8TQQcJwcravlZc1t+cDPU2ZJBlhFGMIhSkUQio6UmFJu6nT7SN6TvPvWlWS87pfOJ5Kirzgup2zSFMcP8byRghf43hD+m6IPQjo2u5P7AO2TYlh9airHZtixWG15Lxfo9MMI5ghdUYQjfGHGjeIUY7/F3wDAQZYGeq5R/oAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "テディベア",
            "title": "テディベア",
            "src": "/static/41b06ba3cba40cf0f2a1f14838b386fd/6e6fb/image2023-1-6_19-45-27.png",
            "srcSet": ["/static/41b06ba3cba40cf0f2a1f14838b386fd/5a46d/image2023-1-6_19-45-27.png 300w", "/static/41b06ba3cba40cf0f2a1f14838b386fd/0a47e/image2023-1-6_19-45-27.png 600w", "/static/41b06ba3cba40cf0f2a1f14838b386fd/6e6fb/image2023-1-6_19-45-27.png 618w"],
            "sizes": "(max-width: 618px) 100vw, 618px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`テディベアの`}</em>{`リマインダーにコメントが付いている様子`}</p>
    <p><strong parentName="p">{`山田`}</strong>{`: なるほど、それでテディベアが出てくるんですね。`}</p>
    <p><strong parentName="p">{`小田`}</strong>{`: TGIF を待つまでの時間がムダだよねっていう話もあって先程の効率化の話も含めて行なっている施策です。`}</p>
    <p><strong parentName="p">{`新倉`}</strong>{`: これまで TGIF やテディベアが無い時は下手をすると一週間誰とも会話をしていないということもあったんですが、この取り組みをしてから `}<strong parentName="p">{`本当にコミュニケーションが増えました`}</strong>{` 。`}</p>
    <h2>{`TGIF の利点とこれからについて`}</h2>
    <p><strong parentName="p">{`山田`}</strong>{`: ありがとうございます。既に話にも出ていますが、TGIF をやって良かった点はどういったものがあるか改めて聞かせてください。`}</p>
    <p><strong parentName="p">{`小田`}</strong>{`: やっぱり格段に「チームでの開発」をしているという意識が高まったことが良いことでした。それまではやはりコミュニケーションなどが不足していたのですが、今は TGIF などのおかげですぐに相談しようという感じにもなって良いチームになってきたなという感触があります。 `}<strong parentName="p">{`さらにチームを良くする土壌`}</strong>{` にするようにしていきたいです。`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: 1 つ目は以前よりお互いのことを知れるようになって心理的安全性が高まったこと、2 つ目はチームとしての方針や共通認識を合わせる場として機能していること、3 つ目は毎週前週からの課題を改善でき、チームや個人の成長を感じられるようになったことです。 `}<strong parentName="p">{`全体的にチーム運営にプラス`}</strong>{` になっていると感じています。`}</p>
    <p><strong parentName="p">{`新倉`}</strong>{`: 私は TGIF が始まってから半年後にジョインしました。元々はエンジニアの皆さんだけのものだと思ってちょっと尻込みしていたところがあったのですが、蓋を開けてみるとプロダクトやチーム自体についての話が多く、 `}<strong parentName="p">{`デザイナーとしても非常に学び`}</strong>{` があるものでした。全体的にはクォーター毎に目標を決めてそれに向けて動くのですが、その目標までの道のりを埋めるという意味でも、TGIF があって良かったです。`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: 時にはエンジニアだけにしかわからない話題などもあったりもしますが、大部分は新倉さんが話したようにプロダクトやチームの改善をいかに行なうかという話題になっているので、そこでデザイナー視点での話が聞けてエンジニアとしても大変助かります。`}</p>
    <p><strong parentName="p">{`古川`}</strong>{`: 自分は入ってからずっと TGIF があるのである種当たり前の存在なのですが…。チームメンバーの人となりなどが分かるのは大変助かります。また、自分とは全然違う経歴を持っているエンジニアの方などは同じ話題でも、 `}<strong parentName="p">{`自分とは全然違う視点を持って話をしていたりするので、すごく勉強になっています`}</strong>{` 。チームの課題が話し合われるので、以前は自分のタスクが終わったら、「次は何をしよう」という感じになっていたんですが、TGIF のおかげで「チームがここで困っているなら自分がやろう」と客観的に分かるようになっています。また、OE は半年毎の自己評価の際にも体現できているかどうかチェックすることになるので、TGIF での目標達成度がそれに役立ちます。`}</p>
    <p><strong parentName="p">{`兒玉`}</strong>{`: 一番は心理的安全性が高くなったことです。エンジニアとしての働き方とセールスをしていた時の働き方が全然違うところが多かったので、1on1 で新居さんとそういった差異について聞くという機会しかなかったんですが、今は TGIF があるのでここで皆さんに聞けるようになって様々な視点でのアドバイスを頂けるようになりました。また、エンジニアとしてお勧めの書籍や勉強法など普通に聞こうとすると、 `}<strong parentName="p">{`あまり機会がないような部分も気軽に聞けるようになったので、勉強の効率も良くなりました`}</strong>{` 。`}</p>
    <p><strong parentName="p">{`山田`}</strong>{`: みなさん、ありがとうございます。それぞれの立場ですごく有用な会なのが分かりますね。 TGIF のこれからについてもお話ください。`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: 先ほども少し話しましたが、形骸化してしまい惰性で行なってしまうということになるのが本当に怖いと思っています。運用することが目的になってしまい、本来の目的が達成できないと本末転倒ですし。今もやっていることですが、ちゃんとメンバー構成や状況に応じて運用をアップデートしていくということが一番大切だと思っています。`}</p>
    <p><strong parentName="p">{`小田`}</strong>{`: 今までエンジニアだけの会から TGIF は始まっていますが、デザイナーの新倉さんが参加してくれたのを始めとして、昨年末から企画職の方も入ってくれるようになったので、裾野の広がりを感じています。こういった立場が別々のメンバーが入ってもスムーズに目的が達成できるように TGIF のアップデートを続けていきたいですね。`}</p>
    <p><strong parentName="p">{`新倉`}</strong>{`: 色々な役割の人がフラットに発言できる場があるおかげで、プロダクトについて総合的な視点を持つことができたと感じています。実際に自分達が試行錯誤して出した機能が「お客様にこんな風に使われて、こんな感想をもらえた」などすぐに共有してもらえるので、開発のモチベーションにもつながる良いサイクルになっています。`}</p>
    <h2>{`Pharms 開発チームでは、どんな人と一緒に働きたいと考えている?`}</h2>
    <p><strong parentName="p">{`山田`}</strong>{`: ありがとうございます。最後に Pharms の開発チームには、どんな方がジョインされると嬉しいですか?`}</p>
    <p><strong parentName="p">{`小田`}</strong>{`: いつもチーム内で話をしているのが「`}<strong parentName="p">{`顧客ファースト`}</strong>{`」という言葉です。これは Pharms に限った話ではなくメドレー全体の話でもあるのですが、顧客や患者などサービスを使っている人達に対して提供できる価値は何かということをきちんと意識して開発ができる方です。技術や数字だけを見るわけじゃなく、その先の顧客をちゃんと考えて開発ができる人ですね。`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: メドレーの事業やバリューに共感があるのは大前提です。また、小田さんが話されたことに加えて、プロダクトをより良くしようと考えてるメンバーと共に、自分はこうしたいという意志も持ちつつ、ときには建設的に議論もしながらプロダクト開発を推進できる人と一緒に仕事ができると嬉しいです。大変なことも勿論ありますが、それを楽しみ、周囲を巻き込みながら技術・技術以外の部分も含め前に進めていける人が良いなと思います。`}</p>
    <p><strong parentName="p">{`山田`}</strong>{`: TGIF も顧客ファーストの理念から生まれたものということになりますよね。ありがとうございました!`}</p>
    <h1>{`さいごに`}</h1>
    <p>{`Pharms 開発チームが実践している「TGIF」というチームビルディングの方法についてインタビューしてきましたが、いかがだったでしょうか? 実際に TGIF
をしている様子などを見ると、和やかな雰囲気ではありますが、全員真剣にプロダクトについて議論をしている姿が印象的でした。`}</p>
    <p>{`こんなチームで調剤薬局や患者のためのプロダクトを作っていきたいと思う方は、ぜひお気軽にお話をしましょう!`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      